// Utils
import { rest } from 'shared/libs/rest';

// Query key
export const QUERY_USER_CANCEL_QUEUE = '/queue/cancel-line';

// Types
export interface UserCancelQueueParams {
  hash: string;
}

export interface UserCancelQueueResponse {}

export const apiUserCancelQueue = async ({
  hash,
}: UserCancelQueueParams): Promise<UserCancelQueueResponse> => {
  return await rest.patch(`${QUERY_USER_CANCEL_QUEUE}/${hash}`, true, {});
};
