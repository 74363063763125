import { HtmlHTMLAttributes, useEffect, useMemo } from 'react';

// Styles
import { Wrap, SubWrap, TextWrap, Title, Text, Link, Button, ErrorButton, Alert } from './styles';

// Components
import { GuestHeader } from 'modules/main/layouts/GuestHeader';
import { MutationAlert } from 'shared/ui/MutationAlert';
import { UserCancelQueueDialog } from 'modules/qr-scan/components/UserCancelQueueDialog';

// Utils
import { parseQrCodeValue } from 'shared/utils/parseQrCodeValue';
import { queryClient } from 'shared/libs/rest';
import { formatDate } from '@tools/ts-format-date';
import { removeDayTimeSeconds } from 'shared/utils/removeDayTimeSeconds';

// Hooks
import { useGetUserData } from 'modules/auth/api/hooks/useGetUserData';
import { useGetWeekDay } from 'shared/hooks/useGetWeekDay';
import { useGetQrCode, QUERY_KEY_QR_CODES } from '../../api/hooks/useGetQrCode';
import { useAddUserIntoQueue } from 'modules/queues/api/hooks/useAddUserIntoQueue';
import { useTranslations } from 'next-intl';
import { useAppRouterState } from '@tools/next-use-app-router-state';

// Components
import { QrScanner } from 'shared/ui/QrScanner';
export interface GuestQrScannerProps extends HtmlHTMLAttributes<HTMLDivElement> {}
export const GuestQrScanner = ({
  className
}: GuestQrScannerProps) => {
  const {
    data: {
      isAuth
    } = {}
  } = useGetUserData();
  const t = useTranslations('QR_SCAN.GUEST_QR_SCANNER');
  const {
    getWeekDay
  } = useGetWeekDay();
  const [qrHash, setQrHash] = useAppRouterState({
    key: 'hash'
  });
  const queryQrCode = useGetQrCode({
    hash: qrHash as string
  }, {
    enabled: !!qrHash
  });
  const {
    data: qrCodeData,
    isFetching: isQrCodeFetching,
    isSuccess: isGetQrCodeSuccess,
    isError: isGetQrCodeError,
    refetch
  } = qrHash ? queryQrCode // If qrHash is set, then use queryQrCode
  : {
    // Otherwise, return a fake object
    data: undefined,
    isFetching: false,
    isSuccess: false,
    isError: false,
    refetch: () => {}
  } as typeof queryQrCode;
  const addUserIntoQueueMutation = useAddUserIntoQueue({}, {
    onSuccess: () => refetch()
  });
  const {
    data: addUserIntoQueueResponse,
    mutate: addUserIntoQueue,
    isSuccess: isAddUserIntoQueueSuccess
  } = addUserIntoQueueMutation;

  // Qr code data
  const {
    user,
    activePenalty,
    queue,
    numberHidden
  } = qrCodeData || {};

  // User data
  const {
    name = '',
    surname = '',
    isBlocked = false
  } = user || {};

  // Penalty data
  const {
    activeTo = '',
    reason = ''
  } = activePenalty || {};
  const isPenalized = !!activePenalty;
  useEffect(() => {
    if (user && !queue) {
      addUserIntoQueue({
        qrCodeHash: qrCodeData?.hash
      });
    }
  }, [user]);
  const handleScan = result => {
    const {
      rawValue
    } = result?.[0] || {};
    console.log('qr code scan result', rawValue);
    const value = parseQrCodeValue(rawValue);
    setQrHash(value as string);
  };
  const handleRetryClick = () => {
    setQrHash(undefined);
    queryClient.removeQueries({
      queryKey: [QUERY_KEY_QR_CODES, {
        hash: qrHash
      }],
      exact: true
    });
    addUserIntoQueueMutation.reset();
  };

  // Queue data
  const {
    date,
    day,
    startTime,
    endTime,
    number,
    isCanceled,
    isCanceledByManager,
    isNotArrived,
    isUsed
  } = useMemo(() => {
    const {
      timeSlot,
      number,
      isCanceled,
      isCanceledByManager,
      isNotArrived,
      isUsed
    } = addUserIntoQueueResponse || queue || {};
    const {
      day,
      startTime,
      endTime
    } = timeSlot || {};
    const {
      date
    } = day || {};
    const dayDate = new Date(date as string);
    return {
      date: formatDate(dayDate, 'DD.MM.YYYY'),
      number,
      day: getWeekDay(dayDate),
      startTime: removeDayTimeSeconds(startTime),
      endTime: removeDayTimeSeconds(endTime),
      isCanceled,
      isCanceledByManager,
      isNotArrived,
      isUsed
    };
  }, [queue, addUserIntoQueueResponse]);
  const isAnyCanceled = isCanceled || isCanceledByManager || isNotArrived;
  const penaltyFormattedDate = useMemo(() => formatDate(new Date(activeTo), 'DD.MM.YYYY'), [activeTo]);
  return <Wrap {...{
    className
  }}>
      <GuestHeader />
      <SubWrap>
        <QrScanner onScan={handleScan} onRetryClick={handleRetryClick} loading={isQrCodeFetching} success={!!qrCodeData} data={qrCodeData} warning={!qrCodeData?.user && isGetQrCodeSuccess} error={isGetQrCodeError} />
        {user && <>
            <TextWrap>
              <Title>
                {name} {surname}
              </Title>

              <MutationAlert mutation={addUserIntoQueueMutation} />
            </TextWrap>

            {/* Aid received text */}
            <Alert show={isUsed} type="success" message={<TextWrap>
                  <Text>{t('GOT_AID')}</Text>
                </TextWrap>} />

            {/* Blocked text */}
            <Alert show={isBlocked} type="error" message={<TextWrap>
                  <Text>{t('BLOCKED')}</Text>
                </TextWrap>} />

            {/* Penalty text */}
            <Alert show={isPenalized && !isBlocked} type="error" message={<TextWrap>
                  <Text>{t('PENALIZED')}</Text>
                  <Text>
                    {t('EXPIRATION_DATE')} {penaltyFormattedDate}
                  </Text>
                  <Text>
                    {t('REASON')} {reason}
                  </Text>
                </TextWrap>} />

            {/* Canceled by user text */}
            <Alert show={isCanceled} type="error" message={<TextWrap>
                  <Text>{t('CANCELED')}</Text>
                </TextWrap>} />

            {/* Canceled by manager text */}
            <Alert show={isCanceledByManager} type="error" message={<TextWrap>
                  <Text>{t('CANCELED_BY_MANAGER')}</Text>
                  {isNotArrived && <Text>{t('NOT_ARRIVED')}</Text>}
                </TextWrap>} />

            {/* Queue text */}
            <Alert show={Boolean(!isAnyCanceled && (isAddUserIntoQueueSuccess || queue))} type="info" message={<TextWrap>
                  <Text>{t('REGISTERED', {
              date
            })}</Text>
                  <Text>{t('COME_TO_GET_AID')}</Text>
                  <Text>{t('DAY', {
              day
            })}</Text>
                  <Text>{t('TIME', {
              startTime,
              endTime
            })}</Text>
                  <Text>
                    {numberHidden ? t('NUMBER_HIDDEN') : t('NUMBER_IN_QUEUE', {
              number
            })}
                  </Text>
                </TextWrap>} />

            {queue && !isAnyCanceled && <UserCancelQueueDialog query={queryQrCode}>
                <ErrorButton disabled={isQrCodeFetching}>
                  {t('CANCEL_BUTTON')}
                </ErrorButton>
              </UserCancelQueueDialog>}

            {isAuth && !!qrCodeData?.hash && <Link href={`/dashboard/qr-scan?hash=${qrCodeData?.hash}`}>
                <Button>{t('MANAGER_BUTTON')}</Button>
              </Link>}
          </>}
      </SubWrap>
    </Wrap>;
};