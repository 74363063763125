import styled, { css } from 'styled-components';

// Components
import { div, span } from '@ui/base-html-tags';
import { Button as UIButton } from 'shared/ui/Button';
import { Link as UILink } from 'shared/ui/Link';
import { Alert as UIAlert, AlertProps } from 'shared/ui/Alert';

// Styled components
export const Wrap = styled(div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SubWrap = styled(div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 20px;
  gap: 20px;
`;
export const TextWrap = styled(div)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 480px;
`;
export const Text = styled(span)`
  ${({
  theme
}) => css`
    font-weight: ${theme.fontWeight.bold};
  `};
`;
export const Title = styled(Text)`
  text-align: center;
  font-size: 20px;
`;
export const Link = styled(UILink)`
  text-decoration: none;
`;
export const Button = styled(UIButton)`
  width: 100%;
  max-width: 200px;
  margin-top: 20px;
`;
export const ErrorButton = styled(Button)`
  ${({
  theme
}) => css`
    background-color: ${theme.color.error};
  `};
`;
export const Alert = styled(UIAlert).attrs(() => ({
  showClose: false
}))<AlertProps>`
  max-width: 500px;
  width: 100%;
`;